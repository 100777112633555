import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl';

export default class extends Controller {
  connect() {
    mapboxgl.accessToken = 'pk.eyJ1IjoicGF3ZWxzd2lkZXIiLCJhIjoiY2xhYjV6bDdqMDJuNjNvbnh0amU1Mml6YiJ9.A39gbTDuQpEUL4tsA_euLA';

    const map = new mapboxgl.Map({
      container: this.element, // container ID
      style: 'mapbox://styles/mapbox/light-v9', // style URL
      center: [20.947271392312,52.276714948571],
      zoom: 14, // starting zoom
      projection: 'globe' // display the map as a 3D globe,
    });

    const marker1 = new mapboxgl.Marker()
      .setLngLat([20.945939000132,52.275865982347])
      .addTo(map);
      
    map.on('style.load', () => {
      map.setFog({}); // Set the default atmosphere style
    });
  }
}


