import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  	var accordionSecion = window.location.hash;
  	var elem = document.getElementById(accordionSecion.replace(/[^a-zA-Z0-9 _]/g, ''));

    if (elem) {
    	// elem.scrollIntoView();
    	elem.classList.remove('hidden');

    	setTimeout(function() {
				const y = elem.getBoundingClientRect().top + window.pageYOffset - 135;
  			window.scrollTo({top: y, behavior: 'smooth', block: 'center'});
		  }, 2);
    }

  }
}