import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="obfuscate"
export default class extends Controller {
  static values = { address: String, contentType: { type: String, default: "email" }}
  static targets = [ "showButton", "addressLink" ]

  connect() {

  }

  show(event) {
  	event.preventDefault();
  	if (this.contentTypeValue === "email") {
  		const decoded = this.decode(this.addressValue);
	    this.addressLinkTarget.setAttribute("href", "mailto:"+decoded);
	    this.addressLinkTarget.classList.remove("hidden");
	    this.showButtonTarget.classList.add("hidden");
	    this.showButtonTarget.classList.remove("msdg-blue-button");  
	    this.addressLinkTarget.innerHTML = decoded;
  	}

  	if (this.contentTypeValue === "phone") {
  		const decoded = this.decode(this.addressValue);
	    this.addressLinkTarget.classList.remove("hidden");
	    this.showButtonTarget.classList.add("hidden");
	    this.showButtonTarget.classList.remove("msdg-blue-button");  
	    this.addressLinkTarget.innerHTML = decoded;
  	}  	
  }

  decode(s) {
  	return atob(s);
  }

}