import "bridgetown-quick-search/dist"
import "index.css"
import { Application } from "@hotwired/stimulus"
import Dropdown from 'stimulus-dropdown'
import Reveal from 'stimulus-reveal-controller'
import * as Turbo from "@hotwired/turbo"
import ahoy from 'ahoy.js';

window.ahoy = ahoy;

window.ahoy.configure({
  urlPrefix: "https://analytics.masdiag.pl",
  visitsUrl: "/msdg-analytics/visits",
  eventsUrl: "/msdg-analytics/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: false,
  cookieDomain: null,
  headers: {},
  visitParams: {},
  withCredentials: false,
  visitDuration: 1 * 60, // 1 hours
  visitorDuration: 1 * 3 * 24 * 60 // 3 days
});
// import Plausible from 'plausible-tracker'

// const plausible = Plausible({
//   domain: 'dev.masdiag.pl',
//   apiHost: 'http://dev.masdiag.pl:8080'
// })

// window.plausible = plausible;

// Uncomment the line below to add transition animations when Turbo navigates.
// We recommend adding <meta name="turbo-cache-control" content="no-preview" />
// to your HTML head if you turn on transitions. Use data-turbo-transition="false"
// on your <main> element for pages where you don't want any transition animation.
//
// import "./turbo_transitions.js"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

window.Stimulus = Application.start()

Stimulus.register('dropdown', Dropdown)
Stimulus.register('reveal', Reveal)

import controllers from "./controllers/**/*.{js,js.rb}"
Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes("_controller.") || filename.includes("-controller.")) {
    const identifier = filename.replace("./controllers/", "")
      .replace(/[_-]controller..*$/, "")
      .replace("_", "-")
      .replace("/", "--")

    Stimulus.register(identifier, controller.default)
  }
})